@import 'partials/variables';

html, body {
  background: rgb(245,245,245);
  color: $color--black;
  margin: 0;
  padding: 0;
  font-family: $font--body;
  font-size: $font-size--body;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: hidden;
  background: rgb(245,245,245);
  background: linear-gradient(0deg, rgba(245,245,245,1) 0%, rgba(255,255,255,1) 100%);
}

#root {
  min-height: 100vh;
}

a {
  color: $color--medium;
  text-decoration: none;
  font-weight: bold;
  transition: 300ms;

  &:hover {
    color: $color--dark;
  }
}

pre, code {
  font-family: $font--forms;
}

code {
  font-size: 20px;
}